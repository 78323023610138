export const pageEditor = {
    upload: {
        title: 'Start uploading and demystifying the paper',
        stepList: [
            "Upload literature",
            "Start running",
            "Analyze literature",
            "Automatic report generation",
            "Export download"
        ],
        desc: 'ReCheck, an innovative AI-powered reference checking tool designed for academic journals, offers researchers a streamlined solution for ensuring citation accuracy. This advanced application allows users to upload their manuscripts in either PDF or Word format for comprehensive reference checking. If you cite some retracted artiles, we can alerts your attention.',
        uploadDescribe: "Click or drag to upload",
        btn: 'Run Immediately',
        notUploadedTip: "Please upload the file first",
        errorUploadType: "The file format is incorrect, please upload the correct file",
        errorUpload: "Upload failed, please try again",
        successUpload: "Upload successful",
        errorAnalyze: "Analyze failed, please try again",
        successAnalyze: "File parsing succeeded",
    },

    result: {
        tabList: [
            "Basic statistical result",
            "Retracted Articles",
            "Distribution of Journal Literature",
            "Annual distribution of journal literature"
        ],
        totalList: [
            "TOTAL REFERENCES",
            "RETRACED ARTICLES",
            "JOURNAL ARTICLES",
            "TOTAL REFERENCES"
        ],
        btn: "Generate Report",
        backBtn: "Back",
        describeList: [
            "The reference statistics for this paper show a total of {a} reference, including {b} journal reference and {c} non journal reference.",
            "The reference statistics for this paper show a total of {a} reference, including {b} journal reference and {c} non journal reference.",
            "The journal distribution of the references in this paper is as follows: {a} article is included in WOS, {b} article is included in Scopus, and {c} article is included in the general journal.",
            "The annual distribution of references in this paper is as follows: {a} article was included in the past 2 years, {b} articles were included in 3-5 years, and {c} articles were included in over 5 years."
        ],
        table: [
            ["JOURNAL ARTICLES", "OTHERS"],
            [],
            ["WOS INCLUSION", "SCOPUS INCLUSION", "GENERAL JOURNAL INCLUSION"],
            ["LITERATURE INCLUDED IN THE PAST 2 YEARS", "LITERATURE INCLUDED IN 3-5 YEARS", "LITERATURE INCLUDED IN OVER 5 YEARS"]
        ],
        charts: [
            ["Proportion of non journal literature", "Proportion of journal literature"],
            "Reference",
            [
                ["Proportion of non WOS inclusion", "WOS inclusion percentage"],
                ["Proportion of non Scopus indexed", "Proportion of Scopus inclusion"]
            ],
            ["Proportion of literature included in the past 2 years", "Proportion of literature included in 3-5 years", "Proportion of literature indexed for more than 5 years"]
        ]
    },
};

export const tableDataEnum = [
    ['journalCount', 'nojournalCount'],
    [],
    ['wosCount', 'scopusCount', 'normalJournalCount'],
    ['twoTotal', 'ttofTotal', 'fiveTotal']
]