import request from './request'

const baseUrl = "/joi";
const jdcBaseUrl = "/jdc";
const rcheckBaseUrl = "/rcapi";
// const baseUrl = "http://47.97.203.109:8848/";

const http = {
	/**
	 * methods: 请求
	 * @param url 请求地址 
	 * @param params 请求参数
	 */


	get(url, params, loading) {
		const config = {
			method: 'get',
			url: baseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.params = params
		return request(config)
	},
	getFhl(url, params, loading) {
		const config = {
			method: 'get',
			url: baseUrl + '/factory' + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.params = params
		return request(config)
	},
	postFhl(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + '/factory' + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.data = params
		return request(config)
	},
	//获取插件journalName
	postJ(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + '/api/paper/' + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.data = params
		return request(config)
	},

	//访问登录权限的接口
	loginTrue(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + '/api/user/login/' + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.data = params
		return request(config)
	},

	//注册接口
	// register(){
	// 	const config = {
	// 		method: 'post',
	// 		url: baseUrl + '/api/user/login/' + url,
	// 		loading: loading == undefined ? "" : "." + loading
	// 	}
	// 	if (params) config.data = params
	// 	return request(config)
	// }


	//访问需要认证的paper接口
	authTrue(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + '/api/paper/auth/' + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.data = params
		return request(config)
	},
	//普通接口
	post(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + '/api/paper/' + url,
			loading: loading == undefined ? "" : "." + loading
		}

		if (params) config.data = params
		return request(config)
	},

	//index页接口
	postI(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + '/api/' + url,
			loading: loading == undefined ? "" : "." + loading
		}

		if (params) config.data = params
		return request(config)
	},
	postP(url, params, loading) {
		const config = {
			method: 'post',
			url: 'https://www.jiansuobao.net/retriveal/EIQueryJournalism',
			loading: loading == undefined ? "" : "." + loading
		}
		// if(params) config.data = params
		config.data = params
		return request(config)
	},
	put(url, params, loading) {
		const config = {
			method: 'put',
			url: baseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.params = params
		return request(config)
	},
	delete(url, params, loading) {
		const config = {
			method: 'delete',
			url: baseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		if (params) config.params = params
		return request(config)
	},
	//登录验证码(post)
	loginCode(url, params, loading) {
		const config = {
			method: 'post',
			url: baseUrl + '/api/msm/' + url,
			loading: loading == undefined ? "" : "." + loading
		}
		// if(params) config.data = params
		config.data = params
		return request(config)
	},
	jdcAjax(url, params, loading) {
		const config = {
			method: 'get',
			url: jdcBaseUrl + url,
			loading: loading == undefined ? "" : "." + loading
		}
		// if(params) config.data = params
		config.data = params
		return request(config)
	},
	postRcheck(url, params, loading) {
		const config = {
			method: 'post',
			url: rcheckBaseUrl + url,
			loading: loading == undefined ? "" : "." + loading,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}

		config.data = params
		return request(config)
	}
}
//导出
export default http